import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Button, Grid, Typography, Table, TableHead, TableBody,
         TableRow, TableCell, Modal, Divider } from '@material-ui/core';
import LoadMenu from 'components/menus/LoadMenu';
import { fetchTransaction, resendReceiptEmail } from 'actions/TransactionActions';
import ReceiptHeader from 'components/ReceiptHeader';
import { formatDateTime } from 'utils/dates';
import { formatCurrency } from 'utils/currency';
import queryString from 'query-string';
import renderHTML from 'react-render-html';
import EyeIcon from '@material-ui/icons/Visibility';
import Formsy from 'formsy-react';
import TextInput from 'components/inputs/TextInput';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';

const styles = theme => ({
  paper: {
    position: 'absolute',
    top: '3em',
    left: '30%',
    width: '40%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    maxHeight: '80%',
    overflow: 'scroll'
  }
});

const staticWidth = {style: {width: '50%'}};
const creditCardImage = {'amex': 'amex.png',
                         'discover': 'discover.png',
                         'eftpos': 'eftpos.png',
                         'mastercard': 'mastercard.png',
                         'visa': 'visa.png'};

export class ReceiptPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openResendReceiptPopup: false
    }

    this.closeResendReceiptPopup = this.closeResendReceiptPopup.bind(this);
    this.handleResendReceipt = this.handleResendReceipt.bind(this);
  }

  componentDidMount() {
    this.props.fetchTransaction(this.props.match.params.id);
  }

  closeResendReceiptPopup() {
    this.setState({openResendReceiptPopup: false});
  }

  renderTransactionDetails() {
    const { transaction } = this.props;

    return (
      <Grid container spacing={16}>
        <Grid item md={6} sm={12} className='full-width'>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className='font-bold' {...staticWidth}>Transaction ID</TableCell>
                <TableCell>{transaction.id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className='font-bold' {...staticWidth}>Transaction Date</TableCell>
                <TableCell>{formatDateTime(transaction.date)}</TableCell>
              </TableRow>
              <TableRow className='hidden-print'>
                <TableCell className='font-bold' {...staticWidth}>{transaction.pos_type}</TableCell>
                <TableCell>{transaction.pos_name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className='font-bold' {...staticWidth}>Payment Method</TableCell>
                <TableCell className='visible-print' style={{padding: '16px 56px 16px 24px'}}>{transaction.visible_print_currency}</TableCell>
                <TableCell className='hidden-print'>
                  {creditCardImage[transaction.credit_card_type] ?
                    <img src={require('images/' + creditCardImage[transaction.credit_card_type])} height='35' />
                    :
                    transaction.hidden_print_currency
                  }
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>

        <Grid item md={6} sm={12} className='full-width'>
          <Table>
            <TableBody>
              {transaction.currency_type === 'card' && transaction.external_ref &&
                <TableRow>
                  <TableCell className='font-bold' {...staticWidth}>Payment Reference</TableCell>
                  <TableCell>{transaction.payment_reference}</TableCell>
                </TableRow>
              }
              {transaction.currency_type === 'invoice' &&
                <React.Fragment>
                  <TableRow>
                    <TableCell className='font-bold' {...staticWidth}>Invoice Number</TableCell>
                    <TableCell>{transaction.invoice_number}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className='font-bold' {...staticWidth}>Transaction Reference</TableCell>
                    <TableCell>{transaction.transaction_reference}</TableCell>
                  </TableRow>
                </React.Fragment>
              }
              {transaction.currency_type != 'card' && transaction.currency_type != 'invoice' && transaction.currency_type != 'cash' &&
                <TableRow>
                  <TableCell className='font-bold' {...staticWidth}>Transaction Reference</TableCell>
                  <TableCell>{transaction.name}</TableCell>
                </TableRow>
              }
              {transaction.customer_name ?
                <TableRow>
                  <TableCell className='font-bold' {...staticWidth}>Customer Name</TableCell>
                  <TableCell>{transaction.customer_name}</TableCell>
                </TableRow>
                :
                null
              }
              {transaction.customer_phone ?
                <TableRow>
                  <TableCell className='font-bold' {...staticWidth}>Customer Phone</TableCell>
                  <TableCell>{transaction.customer_phone}</TableCell>
                </TableRow>
                :
                null
              }
              {transaction.customer_email ?
                <TableRow>
                  <TableCell className='font-bold' {...staticWidth}>Customer Email</TableCell>
                  <TableCell>{transaction.customer_email}</TableCell>
                </TableRow>
                :
                null
              }
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    )
  }

  renderProductDetails() {
    const { transaction, webposProfile } = this.props;

    return (
      <Table style={{marginTop: '1em'}}>
        <TableHead>
          <TableRow>
            <TableCell padding="dense">Product</TableCell>
            <TableCell padding="dense">Type</TableCell>
            <TableCell padding="dense">Card ID</TableCell>
            <TableCell padding="dense">Value</TableCell>
            <TableCell padding="dense">Fee</TableCell>
            <TableCell padding="dense" align="right">Total</TableCell>
            <TableCell padding="none" />
          </TableRow>
        </TableHead>
        <TableBody>
          {transaction.cards.map((card, index) =>
            <TableRow key={index}>
              <TableCell padding="dense">
                {card.name}
                {card.product_type == 'Digital' &&
                  <Grid style={{marginTop: '0.3em', fontStyle: 'italic'}}>To: {card.delivery_first_name} {card.delivery_last_name} - {card.delivery_sms_number}</Grid>
                }
              </TableCell>
              <TableCell padding="dense">{card.product_type}</TableCell>
              <TableCell padding="dense">{card.number_masked}</TableCell>
              <TableCell padding="dense">{formatCurrency(card.value, webposProfile.currency_code)}</TableCell>
              <TableCell padding="dense">{formatCurrency(card.fee, webposProfile.currency_code)}</TableCell>
              <TableCell padding="dense" align="right">{formatCurrency(card.cost, webposProfile.currency_code)}</TableCell>
              <TableCell padding="none" className="hidden-print">
                <NavLink to={'/cards/' + card.id}><EyeIcon color="secondary" /></NavLink>
              </TableCell>
            </TableRow>
          )}
          {this.renderTransactionTotal()}
        </TableBody>
      </Table>
    );
  }

  renderTransactionTotal() {
    const { transaction, webposProfile } = this.props;

    return (
      <React.Fragment>
        <TableRow className='font-bold'>
          <TableCell colSpan={3} />
          <TableCell colSpan={2}>Total Value</TableCell>
          <TableCell align="right">{formatCurrency(transaction.total_value, webposProfile.currency_code)}</TableCell>
        </TableRow>
        <TableRow className='font-bold'>
          <TableCell colSpan={3} />
          <TableCell colSpan={2}>Total Card Fees</TableCell>
          <TableCell align="right">{formatCurrency(transaction.total_card_fees, webposProfile.currency_code)}</TableCell>
        </TableRow>
        {transaction.delivery_fee && transaction.delivery_fee > 0 &&
          <TableRow className='font-bold'>
            <TableCell colSpan={3} />
            <TableCell colSpan={2}>Delivery Fee</TableCell>
            <TableCell align="right">{formatCurrency(transaction.delivery_fee, webposProfile.currency_code)}</TableCell>
          </TableRow>
        }
        {transaction.fees.map((fee, index) =>
          <TableRow key={index} className='font-bold'>
            <TableCell colSpan={3} />
            <TableCell colSpan={2}>{fee.fee_type}</TableCell>
            <TableCell align="right">{formatCurrency(fee.cost, webposProfile.currency_code)}</TableCell>
          </TableRow>
        )}
        <TableRow className='font-bold'>
          <TableCell colSpan={3} />
          <TableCell colSpan={2}>Total Paid</TableCell>
          <TableCell align="right">{formatCurrency(transaction.total_paid, webposProfile.currency_code)}</TableCell>
        </TableRow>
        {transaction.total_gst &&
          <TableRow>
            <TableCell colSpan={3} />
            <TableCell colSpan={2}>Inclusive of GST</TableCell>
            <TableCell align="right">{formatCurrency(transaction.total_gst, webposProfile.currency_code)}</TableCell>
          </TableRow>
        }
      </React.Fragment>
    );
  }

  handleResendReceipt(data, _data, _invalidateForm) {
    this.setState({openResendReceiptPopup: false});
    this.props.resendReceiptEmail(this.props.transaction.id, data.email);
  }

  renderResendReceiptModal() {
    const { isProcessing, classes } = this.props;
    const { openResendReceiptPopup } = this.state;

    return (
      <Modal open={openResendReceiptPopup} onClose={this.closeResendReceiptPopup}>
        <div className={classnames(classes.paper, 'form-container')}>
          <Typography variant='h6' align="center" gutterBottom>Resend Receipt Email</Typography>
          <Divider style={{margin: '1em 0'}} />
          <Formsy onValidSubmit={this.handleResendReceipt}>
            <TextInput name='email' label='Enter Email Address' compact required
              validations='isEmail'
              validationErrors={{
                isEmail: 'Invalid email',
                isDefaultRequiredValue: "can't be blank"
              }}
            />
            <div className="button-container">
              <Button type='submit' variant='contained' color='secondary' disabled={isProcessing}>Send</Button>
            </div>
          </Formsy>
        </div>
      </Modal>
    );
  }

  render() {
    const { transaction, webposProfile } = this.props;

    return (
      <Grid container spacing={16}>
        <Grid item md={2} className='full-width hidden-print'>
          <LoadMenu />
        </Grid>
        <Grid item md={8} sm={12} className='full-width print-full-width' style={{minHeight: '550px'}}>
          {transaction && webposProfile &&
            <React.Fragment>
              <Paper className='form-container receipt' elevation={1}>
                <div className='visible-print'>
                  <ReceiptHeader item={transaction} webposProfile={webposProfile} />
                </div>
                <div className='hidden-print'>
                  {queryString.parse(this.props.location.search).success &&
                    <React.Fragment>
                      {transaction.customer_email &&
                        <Typography variant='body1' align='center' gutterBottom>{'A receipt email has been sent to ' + transaction.customer_email}</Typography>
                      }
                      <Typography variant='body1' align='center' gutterBottom>{renderHTML(transaction.screen_message)}</Typography>
                    </React.Fragment>
                  }
                  <Typography variant='h4' align='center' gutterBottom className="top-space">{transaction.webpos_profile.receipt_title}</Typography>
                </div>
                <div className='visible-print'>
                  <Typography variant='h4' align='center' gutterBottom>TAX INVOICE</Typography>
                </div>

                {this.renderTransactionDetails()}
                {this.renderProductDetails()}

                {transaction.receipt_footer_text &&
                  <Typography variant='subtitle1' align='center' className='font-bold' style={{marginTop: '20px'}}>{transaction.receipt_footer_text}</Typography>
                }

                <div className='hidden-print button-container'>
                  <Button variant='contained' color='default' onClick={() => window.print()}>Print Receipt</Button>
                  <Button variant='contained' color='default' onClick={() => this.setState({openResendReceiptPopup: true})}>Resend Email Receipt</Button>
                  {transaction.webpos_profile.post_load_action_name && transaction.webpos_profile.post_load_action_url &&
                    <a style={{textDecoration: 'none'}} href={transaction.webpos_profile.post_load_action_url} target='_blank'>
                      <Button variant='contained' color='secondary'>{transaction.webpos_profile.post_load_action_name}</Button>
                    </a>
                  }
                </div>
              </Paper>
              <div className='hidden-print'>
                {this.renderResendReceiptModal()}
              </div>
            </React.Fragment>
          }
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    transaction: state.transaction.transaction,
    isProcessing: state.transaction.isProcessing,
    webposProfile: state.session.webposProfile
  }
};

const mapDispatchToProps = {
  fetchTransaction: (id) => fetchTransaction(id),
  resendReceiptEmail: (id, email) => resendReceiptEmail(id, email)
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ReceiptPage))
